import React, { Component } from 'react';
import './invoice.css';

export class PaymentReceipt extends Component {

  handlePrint() {
    window.print();
  }
  
  handleRedirect = (redirect_url) => {
    if (redirect_url && redirect_url != "") {
      window.location.assign(redirect_url);
      window.location.reload();
    }
  }

  render() {
    const storedData = localStorage.getItem('receiptData');
    if (storedData) {
    this.data = JSON.parse(storedData);
    } 
    return (
      <div className='payinvoiceheader bg-light'>
        <div className='button-print' id="receipt">
       {/* <span className='RedirectPage'>
                                  <a href={this.data && this.data.redirect_url}>
                                    Back to Main Page
                                  </a>
                               </span> */}

                              <button className='btn RedirectPage' onClick={() => this.handleRedirect(this.data?.redirect_url)}>
        Back to Main Page
      </button>
        <button type="button" id="printbutton" className="button-text-receipt" name="print"  onClick={() => this.handlePrint()}>
          <img src='/images/print.png' alt='Print Invoice' /> 
           Print
          </button>
        </div>
        <div className='viewReceipt'>
        <div className='row'>
        <div className='col-2 col-sm-3'>
        <a href="#" className={"ccnavbarlogo companylogocontainer "}>
                                    <img src={this.data && this.data.companyLogo} className='companylogoreceipt' />
                                </a>
          </div> 
        <div className='col-8 col-sm-6' id='printreceipt'>
      <div className='receipt-title'>
        Payment Receipt
      </div>  
      <div className='container'>
      <div className='viewReceiptSuccess'>
        Payment Received Successfully
      </div>  
      <div className='receipt-header'>
        Charges
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Total Charge ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.paymentAmount.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Transaction ID:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.transactionId}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Authorization ID:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.authorizationId}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Card Type:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.cardType}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Card#:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.cardNumber}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Card Exp.:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.cardExpMonth + "/" + this.data.cardExpYear}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Transaction Date and Time:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.transactionDate}
      </div>  
      </div>  
      <div className='receipt-header'>
        Billing Information
      </div>  
      <div className='row receipt-column1'>
      <div className='col-3'>
        Name on Card:
      </div>  
      <div className='col-9 receipt-column'>
      {this.data && this.data.cardFirstName + " " + this.data.cardLastName}
      
      </div>  
      </div> 
      <div className='row receipt-column1'>
      <div className='col-3'>
        Card Bill To:
      </div>  
      <div className='col-9 receipt-column'>
        <div className='receipt-addr1'>{this.data && this.data.address}  </div>
       <div className='receipt-addr2'>{this.data && this.data.city}  </div>
        <div className='receipt-addr2'>{this.data && this.data.state}  </div>
        <div className='receipt-addr2'>{this.data && this.data.countryCode}  </div> 
        <div className='receipt-addr2'>{this.data && this.data.zip}  </div> 
        <div className='receipt-addr2'>{this.data && this.data.country}  </div> 
      </div>  

      </div> 
      <div className='receipt-header'>
        Invoice and Account Details
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Invoice Date:
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.invoiceDate}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Invoice Number:
      </div>  
      <div className='col-6 receipt-column2'>
      <a href={this.data && this.data.print_url}  target="_blank" >
      {this.data && this.data.invoice}
            </a>      
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
      Invoice Total ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.invoiceAmount.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
      Credit Card Processing Fee ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.ccprocessingfeeAmount.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div> 
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Adjustments ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.adjustments.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        Invoice Payments ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.invoicePayment.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        New Invoice Balance ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.balance.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>  
      <div className='row'>
      <div className='col-6 receipt-column1'>
        New Account Balance ({this.data && this.data.currencySymbol}):
      </div>  
      <div className='col-6 receipt-column2'>
      {this.data && this.data.totalAccountBalance.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
      </div>  
      </div>
      </div>
      </div> 
      <div className='col-2 col-sm-3'></div> 
      </div> 
      </div> 
      </div>          
    );
  }
}
